import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useCallback } from 'react';
import styled from 'styled-components';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { Logo } from '../components/common/Logo';

const StyledPage404 = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: ${(props) => 32 / props.theme.fontSizeBase}rem 0 0 0;
`;

const StyledContent = styled.div`
	border: ${(props) => 8 / props.theme.fontSizeBase}rem solid #D2A928;
	padding: ${(props) => 64 / props.theme.fontSizeBase}rem 15% 0 15%;
	margin: 8% auto 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	overflow: hidden;
`;

const StyledTitle = styled.h1`
	font-weight: bold;
	font-size: ${(props) => 40 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 40 / props.theme.fontSizeBase}rem;
	margin: 0 0 ${(props) => 8 / props.theme.fontSizeBase}rem 0;
`;

const StyledText = styled.p`
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 23 / props.theme.fontSizeBase}rem;
	margin: 0 0 ${(props) => 24 / props.theme.fontSizeBase}rem 0;
`;

const Styled404Text = styled.span`
	color: ${(props) => props.theme.orange};
	font-size: 35vh;
	font-weight: bold;
	position: absolute;
	bottom: -14vh;

	@media (max-width: 768px) {
		font-size: 15vh;
		bottom: -6vh;
	}
`;

const StyledButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: ${(props) => 8 / props.theme.fontSizeBase}rem ${(props) => 16 / props.theme.fontSizeBase}rem;

	background: ${(props) => props.theme.white};
	border: 2px solid ${(props) => props.theme.orange};
	box-sizing: border-box;
	cursor: pointer;
	margin: 0 0 30vh 0;

	color: ${(props) => props.theme.orange};
	font-weight: bold;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 24 / props.theme.fontSizeBase}rem;
`;

export const Page404: NextPage = () => {
	const router = useRouter();
	const { t } = useTranslation(['notFound']);

	const goToHome = useCallback(() => {
		router.push('/');
	}, [router]);

	return (
		<>
			<NextSeo
				title={t('notFoundTitle') || ''}
				description={t('notFoundText') || ''}
			/>
			<StyledPage404>
				<Logo/>

				<StyledContent>
					<StyledTitle>{ t('notFoundTitle') }</StyledTitle>
					<StyledText>{ t('notFoundText') }</StyledText>

					<StyledButton onClick={() => goToHome()}>
						{ t('notFoundGoBack') }
					</StyledButton>

					<Styled404Text>404</Styled404Text>
				</StyledContent>
			</StyledPage404>
		</>
	);
};

export const getStaticProps = async ({
	locale,
}: {locale: string}) => ({
	props: {
		...(await serverSideTranslations(locale, [
			'notFound',
			'common',
		])),
	},
});

export default Page404;
